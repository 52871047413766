
body, html {
  display: flex;
  flex-direction: column;
  height: 100%;
}

body {
  align-items: center;
  background-color: #fffcf3;
  font-family: Optima;
}
